import React, { useState, useEffect } from 'react';
import './onboarding.css';
import StepNavigation from './StepNavigation';
import SkipConfirmDialog from './SkipConfirmDialog';
import toast, { Toaster } from 'react-hot-toast';

const Onbording = ({ OnFinish }) => {
  const [UrlLink, setUrlLink] = useState('');
  const [counter, setcounter] = useState(0);
  const [ShowSkipConfirmDialog, setShowSkipConfirmDialog] = useState(false);

  const notifyToast = (type, msg) => {
    if (type == 'success') {
      toast.success(msg == null ? 'Updated Successfully...' : msg);
    }
    else if (type == 'error') {
      toast.error(msg == null ? 'Action Failed...' : msg);
    }
    else if (type == 'wait') {
      toast(msg == null ? 'Processing data...' : msg);
    }
  };


  const slides = [
    { primary: 'Unlock the\npower of crypto\nBet, Play and Win!', secondry: "Congratulations, you're officially on our early user waitlist and will be the first one to experience the Crimson magic!", image: '1.png', bgcolor: 'black', color: 'white' },
    { primary: 'Invite your buddies and earn rewards.\n More the merrier!!!', secondry: 'Invite your buddies, increase the coin balanace. airdrop coming soon!!!', image: '2.png', bgcolor: 'black', color: 'white' },
    { primary: 'Your coin balance\n is your share of\n the crypto', secondry: 'Your coin balance determines your share of the crypto. The higher you climb the more you earn!!!', image: '3.png', bgcolor: 'black', color: 'white' },
    { primary: 'Send invite to 5 of your best buddies\n and claim', secondry: '', image: '4.png', bgcolor: 'black', color: 'white' },

  ];


  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
      let telegrmaID = window.Telegram.WebApp.initDataUnsafe.user.id;
      setUrlLink(`https://t.me/atdemoapp_bot/taptowin?startapp=refid`);
      if (telegrmaID) {
        setUrlLink(`https://t.me/atdemoapp_bot/taptowin?startapp=refid${telegrmaID}`);
      }
    }
  }, []);

  const onNextSlide = (count) => {
    console.log('next slide is moved.');
    setcounter(count);
  };

  const handleSkipClaim = () => {
    if (window.navigator && window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
    setShowSkipConfirmDialog(true);
  };

  const handleOnSkipConfirmation = (isSkippedConfiremd) => {
    setShowSkipConfirmDialog(false);
    if (isSkippedConfiremd) {
      OnFinish(true);
    }
  };

  return (
    <div className={`onboarding-container-${slides[counter].bgcolor}`}>
      {ShowSkipConfirmDialog ? <SkipConfirmDialog OnSkipConfirmation={handleOnSkipConfirmation} /> : <></>}
      <div className="user-friend-content" >
        <div className="image-container" style={{ marginTop: "10px" }}  >
          <img src={`images/onboarding/${slides[counter].image}`} alt="buddy" className="loading-icon" />
        </div>

        <h2 style={{ whiteSpace: "pre-line" }} className={`primary-text-${slides[counter].color}`}>{slides[counter].primary}</h2>

        <p className={`secondry-text-${slides[counter].color}`}  >{slides[counter].secondry}</p>
        {counter == 3 ?
          <h2 className="bonus-token" style={{ marginTop: '-20px' }}><img src='images/coin.png' height={35} width={35} style={{ paddingBottom: '6px', marginRight: '-7px', verticalAlign: 'middle' }} /> 150,000</h2>
          : <></>}

        <div className="friends-container">
          <StepNavigation UrlLink={UrlLink} totalSteps={4} onSkip={handleSkipClaim} onNext={onNextSlide} onFinish={OnFinish}></StepNavigation>
        </div>

      </div>
    </div>
  );
};

export default Onbording;
