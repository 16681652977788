import React, { useState, useRef, useEffect } from "react";
import RewardPopup from "./RewardPopup";
import BetterLuckNextTime from "./BetterLuckNextTime";

const SpinWheel = ({ setGame, setIsGameOn }) => {
  const [baseSpins, setBaseSpins] = useState(5);
  const [spinning, setSpinning] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [showCollect, setShowCollect] = useState(false);
  const lastPoints = useRef(0);
  const currentRotation = useRef(0);

  const prizes = [
    { label: "ZERO", value: 0, color: "#9966CC" },
    { label: "+500000", value: 500000, color: "#2D2D2D" },
    { label: "+10", value: 10, color: "#9966CC" },
    { label: "+5000", value: 5000, color: "#2D2D2D" },
    { label: "+1000", value: 1000, color: "#9966CC" },
    { label: "+2000", value: 2000, color: "#2D2D2D" },
    { label: "+10000", value: 10000, color: "#9966CC" },
    { label: "+1000", value: 1000, color: "#2D2D2D" },
  ];

  const spinWheel = () => {
    if (!spinning) {
      const randomSpins = 5 + Math.random() * baseSpins;
      const extraDegrees = Math.random() * 360;
      const totalDegrees = randomSpins * 360 + extraDegrees;

      setSpinning(true);

      // Add new rotation to current rotation for continuous clockwise motion
      const newRotation = currentRotation.current + totalDegrees;
      currentRotation.current = newRotation;

      setRotation(newRotation);

      setTimeout(() => {
        const normalizedDegree = newRotation % 360;
        const prizeIndex = Math.floor(
          (360 - normalizedDegree) / (360 / prizes.length)
        );
        console.log(prizeIndex);
        lastPoints.current = prizes[(prizeIndex + 1) % prizes.length].value;
        setShowCollect(true);
        setSpinning(false);
      }, 4000);
    }
    setBaseSpins((prev) => prev - 1);
  };

  return (
    <div className="wheel-container">
      <button className="close-button" onClick={() => {
        setGame("")
        setIsGameOn(false);
      }}>
        &larr;
      </button>
      <div className="button-container">
        <button className="spin-button">{baseSpins} spins</button>
        <button className="get-more-button">+ Get more spins</button>
      </div>

      <div className="wheel-wrapper">
        <div
          className="wheel"
          style={{
            transform: `rotate(${rotation}deg)`,
            transition: spinning
              ? "transform 4s cubic-bezier(0.2, 0, 0.2, 1)"
              : "none",
          }}
        >
          {prizes.map((prize, index) => {
            const rotation = (360 / prizes.length) * index;
            const nextRotation = (360 / prizes.length) * (index + 1);
            return (
              <div
                key={index}
                className="segment"
                style={{
                  clipPath: `conic-gradient(from ${rotation}deg to ${nextRotation}deg, ${prize.color} 0%, ${prize.color} 100%)`,
                  transform: `rotate(${rotation}deg)`,
                }}
              >
                <div className="segment-content">
                  <div className="segment-text">{prize.label}</div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="center-pointer"></div>
        <div className="top-pointer"></div>
      </div>

      <button
        onClick={spinWheel}
        disabled={spinning || baseSpins <= 0}
        style={{ width: "100%" }}
        className={`spin-button ${spinning || baseSpins <= 0 ? "disabled" : ""
          }`}
      >
        {spinning ? "Spinning..." : "Spin the wheel"}
      </button>

      {showCollect &&
        (lastPoints.current > 0 ? (
          <RewardPopup
            points={lastPoints.current}
            onClose={() => setShowCollect(false)}
          />
        ) : (
          <BetterLuckNextTime
            points={lastPoints.current}
            onClose={() => setShowCollect(false)}
          />
        ))}
    </div>
  );
};

export default SpinWheel;
