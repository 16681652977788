import React, { useEffect } from "react";
import confetti from "canvas-confetti";
import apiService from "../services/apiService";

const RewardPopup = ({ points, onClose }) => {
    useEffect(() => {
        // Trigger confetti animation when the popup appears
        const duration = 3 * 1000; // 3 seconds
        const end = Date.now() + duration;

        (function frame() {
            confetti({
                particleCount: 5,
                spread: 80,
                origin: { y: 0.6 },
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        })();
    }, []);

    const updateCoins = async () => {
        try {
            console.log(points)
            const response = await apiService.post("/balance", {
                amount: points,
                reason: "games",
                description: `Won ${points} `
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="popup-container">
            <div className="popup-card">
                <img src='/images/gamesImage/coinAnimation.gif' alt="coin" width={"50%"} />
                <h2>{points} Coins</h2>
                <p style={{ color: "#FFF" }}>Invite friends to expand your community and earn more coins.</p>
                <button className="awesome-btn" onClick={() => {
                    onClose()
                    updateCoins()
                }}>Collect!</button>
            </div>
        </div>
    );
};

export default RewardPopup;