

import React, { useState, useEffect, useRef } from 'react';
import RewardPopup from './RewardPopup';
import BetterLuckNextTime from './BetterLuckNextTime';
import FallingBackground from '../assets/fallingBackground.jpeg';

const styles = {
    gameContainer: {
        backgroundColor: '#000',
        backgroundImage: `url(${FallingBackground})`,
        width: '100%',
        height: '100vh',
        position: 'relative',
        overflow: 'hidden'
    },
    timer: {
        width: "fit-content",
        textAlign: 'center',
        color: 'red',
        fontWeight: 'bold',
        fontSize: '20px',
        position: 'absolute',
        top: "10px",
        right: "10px"
    },
    score: {
        width: "fit-content",
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '20px',
        position: 'absolute',
        top: "12px",
        left: "50px"
    },
    monster: {
        position: 'absolute',
        cursor: 'pointer',
        backgroundSize: 'contain',  // Try 'cover' instead of 'contain'
        backgroundRepeat: 'no-repeat',
        transition: 'all 0.1s ease',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
};

const MonsterCatchingGame = ({ setGame, setIsGameOn }) => {
    const [score, setScore] = useState(0);
    const [seconds, setSeconds] = useState(60);
    const [gameOver, setGameOver] = useState(false);
    const [monsters, setMonsters] = useState([]);
    const [showCollect, setShowCollect] = useState(false);
    const [heartCount, setHeartCount] = useState(0);
    const [freezeCount, setFreezeCount] = useState(0);

    let bombSound = useRef(null);
    let coinSound = useRef(null);

    let coinChance = 70;
    let bombChance = 5;
    let freezeChance = 10;
    let heartChance = 15;
    let speedOfMonster = 3;
    let delayInGerate = 2000;
    let monsterFrequency = 3;

    const getRandomMonsterType = () => {
        const total = Math.random() * 100;

        if (total < 3) return "/bomb.svg";  // 3% Bomb

        if (total < 5 && freezeCount < 2) {  // 2% Freeze (Max 2 times)
            setFreezeCount(prev => prev + 1);
            return "/freeze.svg";
        }

        if (total < 8 && heartCount < 3) {  // 3% Heart (Max 3 times)
            setHeartCount(prev => prev + 1);
            return "/heart.svg";
        }

        return "/coin.svg";  // 90% Coin
    };

    useEffect(() => {
        const chancesInterval = setInterval(() => {
            if (bombChance <= 60) bombChance += 1.1;
            if (coinChance >= 30) coinChance -= 0.8;
            if (speedOfMonster <= 15) speedOfMonster += 0.2;
            if (freezeChance > 5) freezeChance -= 0.2;
            if (heartChance > 5) heartChance -= 0.3;
            monsterFrequency += 0.1;
        }, 1500);

        if (coinChance <= 20 || bombChance >= 70 || speedOfMonster >= 15) {
            clearInterval(chancesInterval);
        }

        return () => clearInterval(chancesInterval);
    }, []);

    useEffect(() => {
        bombSound.current = new Audio("/sounds/bombSound.mp3");
        coinSound.current = new Audio("/sounds/coinSound.wav");
    }, []);

    const createMonster = () => {
        if (gameOver) return;

        const id = Date.now() + Math.random();
        const size = Math.floor(Math.random() * 20) + 30;
        let left;

        // Prevent monster overlap
        const isOverlapping = (newLeft) => {
            return monsters.some(m =>
                Math.abs(m.left - newLeft) < (size + m.size + 20) &&
                m.top < 300
            );
        };

        do {
            left = Math.floor(Math.random() * (window.innerWidth - size));
        } while (isOverlapping(left));

        const newMonster = {
            id,
            size,
            left,
            top: -100,
            speed: speedOfMonster + (Math.random() * 3),
            type: getRandomMonsterType()
        };

        setMonsters(prev => [...prev, newMonster]);
    };

    useEffect(() => {
        if (gameOver) return;

        const moveInterval = setInterval(() => {
            setMonsters(prev =>
                prev.filter(monster => monster.top <= window.innerHeight)
                    .map(monster => ({ ...monster, top: monster.top + monster.speed }))
            );
        }, 30);

        return () => clearInterval(moveInterval);
    }, [gameOver]);

    useEffect(() => {
        if (gameOver) return;

        for (let i = 0; i < 5; i++) {
            setTimeout(() => createMonster(), i * 700);
        }

        const generationInterval = setInterval(() => {
            const monstersToGenerate = Math.ceil(monsterFrequency);
            for (let i = 0; i < monstersToGenerate; i++) {
                setTimeout(() => createMonster(), i * 500);
            }
        }, delayInGerate);

        return () => clearInterval(generationInterval);
    }, [gameOver]);

    useEffect(() => {
        if (seconds > 0 && !gameOver) {
            const timer = setTimeout(() => setSeconds(prev => prev - 1), 1000);
            return () => clearTimeout(timer);
        } else if (seconds === 0 && !gameOver) {
            setGameOver(true);
            setShowCollect(true);
        }
    }, [seconds, gameOver]);

    const handleAssetClick = (id, type) => {
        setMonsters(prev => prev.filter(monster => monster.id !== id));

        setTimeout(() => {
            if (type === '/bomb.svg') {
                setScore(0);
                bombSound.current.play();
            }
            if (type === '/freeze.svg') {
                setMonsters([]);
            }
            if (type === '/heart.svg') {
                setSeconds(prev => prev + 5);
            }
            if (type === '/coin.svg') {
                setScore(prev => prev + 5);
                coinSound.current.play();
                if ("vibrate" in navigator) {
                    navigator.vibrate(200);
                }
            }
        }, 50);
    };

    return (
        <div style={{ width: "100vw" }}>
            <button className='back-button' onClick={() => {
                setGame("");
                setIsGameOn(false);
            }} >&larr;</button>
            <div style={styles.gameContainer}>
                <div style={styles.timer}>{seconds}s</div>
                <div style={styles.score}>{score < 10 ? `0${score}` : score}</div>
                {monsters.map(monster => (
                    <div key={monster.id}
                        onPointerDown={(e) => {
                            e.stopPropagation();
                            handleAssetClick(monster.id, monster.type);
                        }}
                        style={{
                            ...styles.monster,
                            width: `${monster.size}px`,
                            height: `${monster.size}px`,
                            left: `${monster.left}px`,
                            top: `${monster.top}px`,
                            backgroundImage: `url(${monster.type})`
                        }} >
                    </div>
                ))}
            </div>
            {showCollect && (score > 0 ? <RewardPopup points={score} onClose={() => {
                setShowCollect(false)
                setGame("");
                setIsGameOn(false);
            }} /> : <BetterLuckNextTime points={score} onClose={() => {
                setShowCollect(false)
                setGame("");
                setIsGameOn(false);
            }} />)}
        </div>
    );
};

export default MonsterCatchingGame;