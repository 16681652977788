import React from "react";
import Coins from "../assets/tabs/coins.webp";

const BetterLuckNextTime = ({ points, onClose }) => {

    return (
        <div className="popup-container">
            <div className="popup-card">
                <img src={Coins} alt="coin" width={"50%"} />
                <h2>{points} Coins</h2>
                <p>Better Luck Next Time!!!</p>
                <button className="awesome-btn" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default BetterLuckNextTime;