import React, { useState, useEffect, useRef } from "react";
import apiService from "../../services/apiService";
import "./Casino.css";
import UserEmailInputDialog from "./userEmailInputDialog";
import toast, { Toaster } from "react-hot-toast";
import SpinWheels from "../../games/SpinWheels";
import SlotMachine from "../../games/SlotMachine";
import FallingGame from "../../games/FallingGame";

const Casino = ({ balance, setIsGameOn }) => {
  const [timeLeft, setTimeLeft] = useState("00:00:00");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [email, setEmail] = useState("");
  const timerRef = useRef(null); // Use useRef to store interval ID
  const targetDateTime = new Date("2025-03-01T00:00:00").getTime();
  const [game, setGame] = React.useState("");

  const gameContainer = useRef(null);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date().getTime();
      const difference = targetDateTime - now;

      if (difference <= 0) {
        setTimeLeft("00:00:00");
        clearInterval(timerRef.current); // Ensure interval is cleared when countdown reaches zero
        return;
      }

      setTimeLeft(formatTime(Math.floor(difference / 1000)));
    };

    updateTimer();
    timerRef.current = setInterval(updateTimer, 1000); // Store interval ID in ref

    return () => {
      clearInterval(timerRef.current); // Cleanup interval on unmount
    };
  }, []);

  async function getUser() {
    const userResp = await apiService.get("/current-user");
    const user = userResp.data.data;
    if (userResp.status === 200) {
      localStorage.setItem("currentUser", JSON.stringify(user));
      setEmail(user?.email || "");
    }
  }

  // Format time (HH:MM:SS)
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${secs}`;
  };

  // Countdown Timer Logic

  const handleGoClick = () => setShowEmailInput(true);

  const handleClose = () => setShowEmailInput(false);

  const handleConfirm = async (userEmail) => {
    setShowEmailInput(false);
    try {
      const response = await apiService.post("/update-email", {
        email: userEmail,
      });
      if (response.data.data) {
        getUser();
        toast.success("Email updated successfully.");
      }
    } catch (error) {
      console.error("Error UpdateEmail:", error);
      toast.error("Failed to update email.");
    }
  };

  const scrollToTop = () => {
    if (gameContainer.current) {
      gameContainer.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleInvalidEmail = () =>
    toast.error("Enter a valid email.", { duration: 2000 });

  return (
    <div className="casino-container" ref={gameContainer}>
      <Toaster />
      {/* {!game && (
        <>
          <div className="casino-title">Casino</div>
          <div className="casino-subtitle">Gamble it All!!!</div>
          <div className="casino-image-container">
            <img
              src="images/gold_bars_512.png"
              alt="gold bars"
              className="casino-image"
            />
          </div>
          <div className="casino-timer">{timeLeft}</div>
          {email ? (
            <span className="casino-thanks">
              Thanks for joining the waitlist! Exciting things are on the way.
            </span>
          ) : (
            <>
              <div className="casino-subtitle-green">
                Submit your email to get early access
              </div>
              <div className="casino-content">
                <button className="go-button" onClick={handleGoClick}>
                  GO
                </button>
              </div>
            </>
          )}

          {showEmailInput && (
            <UserEmailInputDialog
              onConfirm={handleConfirm}
              onInvalidEmail={handleInvalidEmail}
              onCancel={handleClose}
            />
          )}
        </>
      )} */}
      {game === "spin-wheels" && <SpinWheels setGame={setGame} setIsGameOn={setIsGameOn} />}
      {game === "slot-game" && <SlotMachine setGame={setGame} setIsGameOn={setIsGameOn} />}
      {game === "falling" && <FallingGame setGame={setGame} setIsGameOn={setIsGameOn} />}
      {game === "" && (
        <div className="game-list">
          <div
            className="game-title"
            onClick={() => {
              setGame("slot-game");
              setIsGameOn(true);
              scrollToTop();
            }}
          >
            <div className="game-image-container">
              <img
                src="/images/gamesImage/slotgame.png"
                alt="slot game"
                className="game-image"
              />
            </div>
            <button className="gamesButton">Slot Game</button>
          </div>

          <div
            className="game-title"
            onClick={() => {
              setGame("falling");
              setIsGameOn(true);
              scrollToTop();
            }}
          >
            <div className="game-image-container">
              <img
                src="/images/gamesImage/falling.PNG"
                alt="falling game"
                className="game-image"
              />
            </div>
            <button className="gamesButton">Falling Game</button>
          </div>

          <div
            className="game-title"
            onClick={() => {
              setGame("spin-wheels");
              setIsGameOn(true);
              scrollToTop();
            }}
          >
            <div className="game-image-container">
              <img
                src="/images/gamesImage/spin.PNG"
                alt="spin game"
                className="game-image"
              />
            </div>
            <button className="gamesButton">Spin Wheels</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Casino;
