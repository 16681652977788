import React, { useState, useEffect } from 'react';
import './SlotMachine.css';
import RewardPopup from './RewardPopup';
import apiService from '../services/apiService';

const SlotMachine = ({ setGame, setIsGameOn }) => {
	const symbols = ['🍒', '7️⃣', '🟡', '🔔', '🍇'];

	// Prize configuration
	const prizes = [
		{ label: 'ZERO', value: 0, color: '#9966CC' },
		{ label: '+500000', value: 500000, color: '#2D2D2D' },
		{ label: '+10', value: 10, color: '#9966CC' },
		{ label: '+5000', value: 5000, color: '#2D2D2D' },
		{ label: '+1000', value: 1000, color: '#9966CC' },
		{ label: '+2000', value: 2000, color: '#2D2D2D' },
		{ label: '+10000', value: 10000, color: '#9966CC' },
		{ label: '+1000', value: 1000, color: '#2D2D2D' },
	];

	const [reels, setReels] = useState([
		{ symbol: symbols[0], spinning: false, symbols: [] },
		{ symbol: symbols[1], spinning: false, symbols: [] },
		{ symbol: symbols[2], spinning: false, symbols: [] }
	]);
	const [spinning, setSpinning] = useState(false);
	const [credits, setCredits] = useState(10000);
	const [betAmount, setBetAmount] = useState(1000);
	const [spinsLeft, setSpinsLeft] = useState(10);
	const [showWinMessage, setShowWinMessage] = useState(false);
	const [winAmount, setWinAmount] = useState(0);

	// Initialize reels with random symbols
	useEffect(() => {
		const initialReels = reels.map(reel => {
			const reelSymbols = [];
			for (let i = 0; i < 15; i++) {
				reelSymbols.push(symbols[Math.floor(Math.random() * symbols.length)]);
			}
			return { ...reel, symbols: reelSymbols };
		});
		setReels(initialReels);
		setBetAmount(1000)
	}, []);

	const spin = () => {
		// Check if player has enough credits and spins
		if (credits < betAmount || spinsLeft <= 0 || spinning) {
			return;
		}

		// Reset win state
		setShowWinMessage(false);
		setWinAmount(0);

		// Deduct credits and spins
		setCredits(prev => prev - betAmount);
		setSpinsLeft(prev => prev - 1);

		// Start spinning
		setSpinning(true);

		// Create new symbols for each reel
		const newReels = reels.map(reel => {
			const newSymbols = [];
			for (let i = 0; i < 15; i++) {
				newSymbols.push(symbols[Math.floor(Math.random() * symbols.length)]);
			}
			return { ...reel, spinning: true, symbols: newSymbols };
		});

		setReels(newReels);

		// Stop each reel after a random delay
		reels.forEach((_, index) => {
			const stopTime = 1000 + Math.random() * 500 + (index * 500);

			setTimeout(() => {
				setReels(prev => {
					const newReels = [...prev];
					newReels[index] = {
						...newReels[index],
						spinning: false,
						symbol: newReels[index].symbols[7] // Middle symbol
					};
					return newReels;
				});

				// Check if this is the last reel
				if (index === reels.length - 1) {
					setTimeout(() => {
						setSpinning(false);
						checkWin();
					}, 300);
				}
			}, stopTime);
		});
	};

	const checkWin = () => {
		const symbols = reels.map(reel => reel.symbol);
		let prize = 0;

		// Check for 777 jackpot
		if (symbols[0] === '7️⃣' && symbols[1] === '7️⃣' && symbols[2] === '7️⃣') {
			prize = 50000;
			setWinAmount(prize);
			setCredits(prev => prev + prize);
			setShowWinMessage(true);
			return;
		}

		// Check if all symbols are the same
		if (symbols[0] === symbols[1] && symbols[1] === symbols[2]) {
			// Award prize based on symbol
			switch (symbols[0]) {
				case '7️⃣':
					prize = prizes[1].value; // +500000
					break;
				case '🟡':
					prize = prizes[6].value; // +10000
					break;
				case '🔔':
					prize = prizes[3].value; // +5000
					break;
				case '🍒':
					prize = prizes[5].value; // +2000
					break;
				case '🍇':
					prize = prizes[4].value; // +1000
					break;
				default:
					prize = prizes[2].value; // +10
			}
		}
		// Check if two symbols are the same
		else if (symbols[0] === symbols[1] || symbols[1] === symbols[2] || symbols[0] === symbols[2]) {
			// Find which symbol is repeated
			let repeatedSymbol;
			if (symbols[0] === symbols[1]) {
				repeatedSymbol = symbols[0];
			} else if (symbols[1] === symbols[2]) {
				repeatedSymbol = symbols[1];
			} else {
				repeatedSymbol = symbols[0];
			}

			// Award prize based on repeated symbol
			switch (repeatedSymbol) {
				case '7️⃣':
					prize = prizes[6].value; // +10000
					break;
				case '🟡':
					prize = prizes[3].value; // +5000
					break;
				default:
					prize = prizes[7].value; // +1000
			}
		}

		if (prize > 0) {
			setWinAmount(prize);
			setCredits(prev => prev + prize);
			setShowWinMessage(true);
		}
	};

	const resetGame = () => {
		setCredits(10000);
		setSpinsLeft(10);
		setShowWinMessage(false);
	};

	const updateCoins = async () => {
		try {
			const response = await apiService.post("/balance", {
				amount: credits,
				reason: "Slot Machine",
				description: `Won ${credits} from Slot Machine`
			})

			console.log("winning response", response)
			console.log("winning amount", credits)
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div className="slot-machine">
			<button className='back-button' onClick={() => {
				setGame("")
				setIsGameOn(false);
			}}>&larr;</button>
			<div className="slot-container">
				<div className="reels">
					{reels.map((reel, index) => (
						<div key={index} className="reel-container">
							<div className={`reel-wrapper ${reel.spinning ? 'spinning' : ''}`}>
								{reel.symbols.map((symbol, symIndex) => (
									<div key={symIndex} className="reel-symbol">
										{symbol}
									</div>
								))}
							</div>
						</div>
					))}
				</div>

				{showWinMessage && (
					<RewardPopup points={winAmount.toLocaleString()} onClose={() => setShowWinMessage(false)} />
				)}
			</div>

			<div className="game-info">
				<div className="bet-info">
					<span>spin for</span>
					<span className="euro-symbol">🟡</span>
					<span className="bet-amount">{betAmount.toLocaleString()}</span>
					<span className="divider">•</span>
					<span className="spins-left">{spinsLeft}/10 spins left</span>
				</div>
				<div className="credit-display">
					Total credits: {credits.toLocaleString()}
				</div>
			</div>

			<button
				className="spin2-button"
				onClick={spin}
				disabled={spinning || credits < betAmount || spinsLeft <= 0}
			>
				Spin
			</button>

			{(credits < betAmount || spinsLeft <= 0) && (
				<button className="reset-button" onClick={() => {
					resetGame()
					updateCoins()
				}}>
					Reset Game
				</button>
			)}
		</div>
	);
};

export default SlotMachine;